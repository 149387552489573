$color-white: #ffffff;
$color-alabaster: #F3EFE0;
$color-jet: #333;
$color_green: #1D3808;
$color-green-munsell: #00a676;
$color-rocket-metallic: #8f857d;
$font-color: $color-jet;
$header-font: 'Amatic SC',
cursive;
$text-center: center;
$text-justify: justify;
