@import url('https://fonts.googleapis.com/css?family=Amatic+SC');
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow');
@import url('https://fonts.googleapis.com/css?family=Quicksand');

@import "scss/promotions";
@import "scss/variables";

* {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}


body,
html {
  height: 100%;
  width: 100%;
  color: $color-jet;
}

a {
  all: unset;
  cursor: pointer;
}

ol li {
  list-style: none;
  counter-reset: li;
}

p {
  padding: 10px;
  // white-space: pre-wrap;
}

.about-container {
  line-height: 26px;
  max-width: 900px;
  padding-bottom: 50px;
  padding-top: 50px;
  text-align: $text-center;

  hr {
    border: 0;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, rgba(29, 56, 8, 0), rgba(29, 56, 8, 0.75), rgba(29, 56, 8, 0));
    margin-bottom: 20px;
  }
}

.about-row {
  flex-basis: 100%;
  background-color: $color-alabaster;
}

.center {
  justify-content: center;
  align-items: center;
}

.close-button {
  display: none;
}

.contact-section {
  .contact-container {
    color: $color-jet;
    max-width: 1180px;
    line-height: 26px;
    margin-bottom: 25px;
    padding: 25px;

    .map {
      iframe {
        min-height: 400px;
        max-width: 100%;
      }
    }

    .informations {
      background-color: $color-alabaster;
      margin: 25px;
      padding: 25px;
      text-align: left;

      p {
      }
    }
  }

  hr {
    border: 0;
    height: 1px;
    width: 50%;
    background-image: linear-gradient(to right, rgba(29, 56, 8, 0), rgba(29, 56, 8, 0.75), rgba(29, 56, 8, 0));
    margin-bottom: 25px;
  }

  h2 {
    margin: 25px 0;
    font-weight: normal;
  }
}

.dermation-description {
  color: $color-white;

  p {
    padding: 25px;
    text-align: $text-center;
    width: 60%;
  }
}

.dermation-row {
  flex-wrap: wrap-reverse;
  height: 800px;

  figure {
    img {
      max-width: 550px;
    }
  }
}

.dermation-section {
  background-color: $color-rocket-metallic;
  background-image: url("/img/portret.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;

  .dermation-row {
        img {
          border: 7px solid #333;
          max-width: 500px;
    }
  }

}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-container {
  display: flex;
}

.flex-item {
  flex: 0 0 100%;
}

.flex-left {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row {
  flex-direction: row;
}

.flex-end {
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

footer {
  background-color: $color-alabaster;
  color: $color-jet;
  flex-wrap: wrap;
  font-size: 16px;
  padding: 0 10%;
  text-align: $text-center;

  .footer-container {
    .column {
      flex: 33%;
    }

    .copyrights {
      margin-top: 10px;
    }

    .footer-right {
      .social-media {
        .social-list {
          li {
            margin-left: 20px;
          }
        }
      }
    }

    .footer-links {

      ul {
        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;
        margin: 20px 0px;

        li {
          padding: 0.5em;
        }

        li:before {
          content: "•";
          padding-right: 0.5em;
        }
      }
    }

    .footer-left {
    }

    .footer-logo {
      .logo {
        img {
          max-height: 200px;
          max-width: 200px;
        }
      }
    }

    .footer-top {
      height: 500px;
    }

    .footer-social {
    }

    .footer-title {
      font-size: 18px;
    }
  }
}

header {
  background-color: $color-white;
  background-image: url("/img/bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;
  flex-direction: column;
  height: 100%;
}

.header-title {
  flex-basis: 100%;
  font-size: 55px;
  text-align: $text-center;
  text-transform: capitalize;
  letter-spacing: 6px;
  width: 100%;

  h1 {
    padding: 20px;
    font-family: $header-font;
    text-shadow: 3px 3px #000000;
  }

  p {
    font-family: $header-font;
    text-shadow: 2px 2px #000000;
  }
}

hr {
  border: 0;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
}

.navbar {
  flex: 0 0 100%;
  flex-flow: row wrap;
  position: absolute;

  ul {
    transition: 200ms linear;
    flex-flow: row wrap;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    text-transform: uppercase;

    .nav-logo {
      display: none;
    }

    li {
      cursor: pointer;
      font-family: $header-font;
      font-size: 40px;
      font-weight: bold;
      padding: 20px;
      letter-spacing: 2px;
      height: 100%;

      a {
        padding: 10px;
      }

      figure {
        img {
          max-height: 30px;
          max-width: 30px;
        }
      }
    }

    li:not(:first-child):hover {
      background-color: $color-white;
      color: $color-jet;
      cursor: pointer;
      transition: all 0.2s ease;

      a {
        -webkit-border-bottom: 1px solid $color-white;
      }
    }
  }
}

.navbar-scrolled {
  background-color: $color-alabaster;
  color: $color-jet;
  transition: all 0.2s ease;
  z-index: 1000;

  li:not(:first-child):hover {
    background-color: $color-jet !important;
    color: $color-alabaster !important;
    cursor: pointer;
    transition: all 0.2s ease;
  }
}

.navbar-toggle {
  display: none;
}

.noscroll {
  overflow: hidden;
}

.offer-container {
  flex-wrap: wrap;
  max-width: 1400px;
  align-items: flex-start;

  .offer-box {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 300px;
    margin: 10px;
    padding: 50px 10px;
    /*	visibility: hidden !important;*/
    text-align: $text-center;
    transition: all 0.4s ease;
    overflow: hidden;

    figure {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      overflow: hidden;
      position: relative;
      z-index: -1;
    }

    img:hover {
      background-color: white;
    }

    img {
      border-radius: 50%;
      transition: all 0.4s ease;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .offer-button {
    background-color: $color-rocket-metallic;
    border-radius: 10px;
    color: white;
    display: none;
    cursor: pointer;
    margin-top: 20px;
    padding: 15px 32px;
    transition: all 0.5s;
    width: 100px;

    span {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
    }

    span:after {
      content: '\00bb';
      position: absolute;
      opacity: 0;
      top: 0;
      right: -20px;
      transition: 0.5s;
    }
  }

  .offer-button:hover {
    span {
      padding-right: 25px;
    }

    span:after {
      opacity: 1;
      right: 0;
    }
  }
}

.offer-box:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.4s ease;

  figure {
    img {
      transform: scale(1.2);
    }
  }

  figure:hover {
    background-color: red;
  }
}

.offer-description {
  margin: 25px 0;
  max-width: 1400px;
  text-align: $text-center;
}

.offer-section {
  padding: 25px 0;

  .scroll-down {
    animation: action 1.5s infinite alternate;

    @keyframes action {
      0% {
        transform: translateY(-10px);
      }

      100% {
        transform: translateY(30px);
      }
    }
  }
}

.price-container {
  flex-direction: column;
  padding: 50px 0;
  width: 1000px;
}

.price-table {
  border-collapse: collapse;
  text-align: center;
  width: 80%;

  tbody {
    tr:hover {
      background-color: $color-alabaster;
      color: $color-jet;
      transform: scale(1.1);
    }
  }

  tr {
    transition: all 0.2s ease-in-out;
    text-align: $text-center;
  }

  td,
  th {
    border-bottom: 1px solid $color-jet;
    padding: 15px;
  }
}

.products-container {
  max-width: 1200px;

  figure {
    margin: 25px 0;

    img {
      max-width: 100%;
    }
  }

  h1 {
    border-top: 4px solid $color-alabaster;
    border-bottom: 4px solid $color-alabaster;
    font-style: italic;
    padding: 10px 0;
    margin: 10px 0;
    text-align: center;
  }

  h2 {
    border-bottom: 4px solid $color-alabaster;
    margin: 25px 0;
    font-weight: normal;
    padding-bottom: 10px;
  }

  ul {
    line-height: 26px;
    margin: 0 25px;

    li:before {
      content: "-";
      padding-right: 0.5em;
    }
  }
}

.return-to-top {
  position: fixed;
  bottom: 22px;
  height: 60px;
  left: 17px;
  display: block;
  text-decoration: none;
  display: none;
  transition: all 0.4s ease;
  width: 60px;

  i {
    color: $color-jet;
    margin: 0;
    position: relative;

    transition: all 0.4s ease;
  }
}

.return-to-top:hover {
  i {
    transform: rotate(360deg);
    color: $color-jet;
    top: -10px;
  }
}

.section2 {
  background-color: green;
  height: 500px;
}

.title {
  padding: 20px;
  text-transform: capitalize;
  //font-weight: lighter;
}

ul {
  list-style: none;
  text-decoration: none;
}

.sidenav {
  color: $color-jet;
  flex-direction: column;
  font-family: $header-font;
  align-items: center;
  height: 100%;
  width: 0;
  position: fixed;
  background-color: $color-alabaster;
  overflow-x: hidden;
  transition: all 0.4s ease;
  padding-top: 60px;
  z-index: 1000;

  a {
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    font-size: 40px;
    transition: all 0.4s ease;
    text-transform: uppercase;
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
}

.slide-up {
  visibility: hidden !important;
}

.small-header {
  background-attachment: fixed;
  background-color: $color-white;
  background-image: url("/img/bg_mini2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  color: $color-white;
  flex-direction: column;
  height: 40%;
}

.small-header-title {
  flex-basis: 100%;
  flex-direction: column;
  font-size: 35px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;

  p {
    border-bottom: 1px solid $color-white;
    letter-spacing: 4px;
    font-family: $header-font;
    font-size: 70px;
    margin-bottom: 10px;
  }
}

.text-center {
  text-align: $text-center;
}

.treatments-section {
  max-width: 1200px;
  margin: auto;

  .main-treatments-section {
    h1 {
      margin: 25px 0;
      text-transform: capitalize;
      border-bottom: 4px solid $color-alabaster;
      font-weight: normal;
      padding-bottom: 10px;
    }

    .treatments-container {
      margin: 25px 0;

      figure {

        margin: 25px 0;
        max-width: 400px;
        max-height: 400px;

        img {
          box-shadow: 10px 10px 28px -6px rgba(0, 0, 0, 0.75);
          max-width: 400px;
          max-height: 400px;
        }
      }

      p {
        text-align: $text-justify;
        margin: 0 25px;
      }

      ul {
        margin: 0 50px;
      }

      li::before {
        content: "•";
        margin-right: 1em;
      }
    }

    padding-top: 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .dermation-section {
    background-attachment: scroll;
  }

  footer {
    .footer-container {
      flex-wrap: wrap;

      .footer-left {
        order: 1;
      }

      .footer-logo {
        order: 3;
      }

      .footer-right {
        order: 2;
      }
    }

  }

  header {
    background-attachment: scroll;
  }

  .header-container {
    height: 100%;
  }

  .header-title {
    font-size: 20px;

    h1 {
      text-shadow: 1px 1px #000000;
    }

    p {
      text-shadow: 1px 1px #000000;
    }
  }

  .navbar {
    display: flex;
    height: 100%;
    width: 0;
    background-color: rgba(0, 0, 0, 0.9);
    /*    overflow-x: hidden;*/
    transition: all 0.4s ease;
  }

  .mobile-navbar {
    display: block;
    top: 15%;
    text-align: center;
    margin-left: -1000px;
  }

  .navbar-mobile-scrolled {
    background-color: $color-alabaster;
    color: $color-jet;
    transition: all 0.2s ease;
  }

  .navbar-toggle {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    position: fixed;
    transition: all 0.2s ease;
    width: 100%;

    i {
      cursor: pointer;
    }

    .close-button {
      cursor: pointer;
      display: flex;
      position: absolute;
      top: 20px;
      right: 45px;
    }
  }

  .price-table {
    tr:not(:first-child):hover {
      background-color: transparent;
      color: black;
      transform: scale(1.1);
    }
  }

  .offer-container {
    flex-wrap: wrap;
    max-width: 1400px;
    align-items: flex-start;

    .offer-box {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 300px;
      margin: 10px;
      padding: 50px 10px;
      /*	visibility: hidden !important;*/
      text-align: $text-center;
      transition: none;
      overflow: hidden;

      figure {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        overflow: hidden;
        position: relative;
        z-index: -1;
      }

      img {
        border-radius: 50%;
        transition: none;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .offer-box:hover {
      box-shadow: none;

      figure {
        img {
          transform: none;
        }
      }
    }

    .offer-button {
      background-color: $color-rocket-metallic;
      border-radius: 10px;
      color: white;
      display: inline;
      cursor: pointer;
      margin-top: 20px;
      padding: 15px 32px;
      transition: all 0.5s;
      width: 100px;

      span {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;
      }

      span:after {
        content: '\00bb';
        position: absolute;
        opacity: 0;
        top: 0;
        right: -20px;
        transition: 0.5s;
      }
    }

    .offer-button:hover {
      span {
        padding-right: 25px;
      }

      span:after {
        opacity: 1;
        right: 0;
      }
    }
  }

  .small-header {
    background-attachment: scroll;
  }

  .main-treatments-section {
    padding: 0 10px;

    .treatments-container {
      flex-wrap: wrap;
      text-align: center;

      ul {
        padding: 0 10px;
        text-align: left;

        li {

          padding: 0 10px;
          text-indent: -1em;
        }
      }

      .treatments-wrap-left {
        flex-wrap: wrap;
      }

      .treatments-wrap-right {
        flex-wrap: wrap-reverse;
      }
    }
  }
}


@media screen and (min-width:2560px) {
  .dermation-row {
    flex-wrap: wrap-reverse;
    height: 1000px;
  }
}

@media only screen and (max-width: 500px) {

  .dermation-section {
    .dermation-row {
      img {
        border: 7px solid #333;
        max-width: 300px;
      }
    }
  }

  .main-treatments-section {
    max-width: 400px;
  }
}

.w-100 {
  width: 100%;
}
