@import "scss/variables";


.promotion-section {

  padding: 60px 0;

  .promotion-container {
    max-width: 1200px;

    .description {
      max-width: 700px;

      .google-profile-link {
        color: $color-jet;
        font-weight: bold;
      }

      p {
        text-align: justify;
      }

      small {
        text-align: justify;
      }
    ;
    }

    form {
      border: 1px solid $color-alabaster;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      height: 150px;
      margin: 0 10px;

      .file-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

    }

    input[type="file"] {
      display: none;
    }

  }
}

/* The Modal (background) */
.success-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 350px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  /* Modal Content */
  .success-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    max-width: 500px;

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .description {
      text-align: justify;
      margin-right: 10px;
    }

    .promo-code {
      text-align: justify;
    }


  }

}

.sl-button {
  height: 40px;
  width: 100px;
  background-color: $color-alabaster;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  font-weight: bold;
  color: $color-jet;
  cursor: pointer;
}

.sl-file-upload-button {
  border: 1px solid $color-alabaster;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 300px;
}

